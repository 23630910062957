<template>
  <o-table :title="$t('core.users')" ref="tableList" url="/api/system/users" :custom-row-button="true">
    <template slot="filter">
      <o-filter v-on:filter-updated="(filters) => $refs.tableList.setFilters(filters)">
        <o-filter-text :title="$t('core.email')" column="email"></o-filter-text>
      </o-filter>
    </template>
    <template slot="head">
      <o-table-header column="name">{{$t('core.name')}}</o-table-header>
      <o-table-header column="email">{{$t('core.email')}}</o-table-header>
      <o-table-header column="phone">{{$t('core.phone')}}</o-table-header>
    </template>
    <template slot="body" slot-scope="props">
      <td>{{ props.item.name }}</td>
      <td>{{ props.item.email }}</td>
      <td>{{ props.item.phone }}</td>
    </template>
    <template slot="body-controls" slot-scope="props">
      <td class="text-right">
        <div class="btn-group btn-group-sm" role="group" aria-label="edit controls">
          <b-btn variant="secondary" v-if="user.id !== props.item.id" @click="impersonateUser(props.item)"><fa icon="sign-in-alt"/></b-btn>
          <o-delete method="delete" v-if="user.id !== props.item.id"
                    :url="$refs.tableList.deleteUrl() + '/' + props.item.id" v-on:action-executed="$refs.tableList.search">
          </o-delete>
          <router-link :to="{ path: $route.path + '/' + props.item.id }" class="btn btn-primary">
            <fa icon="pencil-alt"/>
          </router-link>
        </div>
      </td>
    </template>
  </o-table>
</template>

<script>
import axios from 'axios'
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters({
      user: 'auth/user'
    })
  },
  methods: {
    impersonateUser (user) {
      axios.post(`/api/system/users/${user.id}/impersonate`).then((response) => {
        if (!response.data) {
          return
        }
        // save current token so that we can easily revert back
        this.$store.dispatch('auth/saveOriginalToken', {
          originalToken: this.$store.getters['auth/token'],
          remember: false
        })
        // Save the token.
        this.$store.dispatch('auth/saveToken', {
          token: response.data,
          remember: false
        })
        // Fetch the user.
        this.$store.dispatch('auth/fetchUser')
        // reload page, we don't update the navigation and other things dynamically
        const homePage = this.$router.resolve({ name: 'home' })
        window.location.href = homePage.href
      }).catch((error) => {
        window.alert('Something went wrong, do you have the permission to impersonate?')
      })
    }
  }
}
</script>
